import { bpath } from '../../../helpers/url-tools';
import { engageApi } from '../engage.api';
import { UpdateUserProfileQuery, UserProfile } from './user-profile.interface';

const userProfilePath = '/profile';

export const userProfileApi = engageApi
  .enhanceEndpoints({
    addTagTypes: ['UserProfile'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: build => ({
      getUserProfile: build.query<UserProfile, void>({
        query: () => ({
          url: bpath({ pathname: userProfilePath }),
          method: 'GET',
        }),
        providesTags: ['UserProfile'],
      }),

      updateUserProfile: build.mutation<UserProfile, UpdateUserProfileQuery>({
        query: ({ body }) => ({
          url: bpath({ pathname: userProfilePath }),
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['UserProfile'],
      }),

      syncUserProfile: build.mutation<UserProfile, void>({
        query: () => ({
          url: bpath({ pathname: `${userProfilePath}/sync` }),
          method: 'POST',
        }),
        invalidatesTags: ['UserProfile'],
      }),

      verifyUserProfile: build.mutation<UserProfile, void>({
        query: () => ({
          url: bpath({ pathname: `${userProfilePath}/verify` }),
          method: 'POST',
        }),
        invalidatesTags: ['UserProfile'],
      }),
    }),
  });

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useSyncUserProfileMutation,
  useVerifyUserProfileMutation,
} = userProfileApi;

export default userProfileApi;
