import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input, { InputSlotsAndSlotProps } from '@mui/joy/Input';
import {
  Controller,
  FieldPath,
  FieldValues,
  get,
  useFormContext,
} from 'react-hook-form';

interface RhfTextSlotProps {
  formControl?: React.ComponentPropsWithoutRef<typeof FormControl>;
  formLabel?: React.ComponentPropsWithoutRef<typeof FormLabel>;
  formHelperText?: React.ComponentPropsWithoutRef<typeof FormHelperText>;
}

interface RhfTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<React.ComponentPropsWithoutRef<typeof Input>, 'slotProps'> {
  name: TName;
  label?: React.ReactNode;
  description?: React.ReactNode;
  min?: number;
  max?: number;
  slotProps?: RhfTextSlotProps & InputSlotsAndSlotProps['slotProps'];
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
  ) => void;
}

const RhfText = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RhfTextProps<TFieldValues, TName>,
) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>();

  const { name, label, description, slotProps = {}, onChange, ...rest } = props;

  const {
    formControl: formControlProps = {},
    formLabel: formLabelProps = {},
    formHelperText: formHelperTextProps = {},
    ...inputSlotProps
  } = slotProps;

  // Handle fullWidth for FormControl
  if (props.fullWidth) {
    formControlProps.sx = {
      ...formControlProps.sx,
      width: '100%',
    };
  }

  // Set default input props
  inputSlotProps.input = {
    min: 0,
    ...inputSlotProps.input,
    type: 'text',
  };

  const errorMessages = get(errors, name + '.message', undefined) as
    | string
    | undefined;

  const error = !!errorMessages;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl {...formControlProps} error={error}>
          {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
          <Input
            {...rest}
            {...field}
            slotProps={inputSlotProps}
            onChange={event => {
              field.onChange(event);
              if (typeof onChange === 'function') {
                onChange(event, event.target.value);
              }
            }}
          />
          {(errorMessages || description) && (
            <FormHelperText {...formHelperTextProps}>
              {errorMessages ?? description}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default RhfText;
